input[type="button"],
input[type="reset"],
input[type="submit"],
.btn, button {
    cursor: pointer;
    margin: 0;
    font-size: 12px;
    vertical-align: middle;
}

input[type=text].btn-input {
    width: 90px;
}

input[type="button"]:not(.dropdown-toggle),
input[type="reset"]:not(.dropdown-toggle),
input[type="submit"]:not(.dropdown-toggle),
.btn:not(.dropdown-toggle), button:not(.dropdown-toggle) {
    text-transform: uppercase;
}

input[type="button"],
input[type="submit"],
.btn, button {
    height: 26px;
    line-height: 24px !important;
    font-weight: 600;
    text-align: center;
    /*border: 1px solid rgba(0,0,0,0.1);*/
    padding: 0 10px;
    line-height: 2.5rem;
    outline: 0;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 0 0 rgba(255,255,255,0.07) inset,0 1px 2px 0 rgba(0,0,0,0.08),0 1px 0 0 rgba(0,0,0,0.06);
    text-shadow: 0 -1px 0 rgba(0,0,0,0.14);
}

input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
.btn:hover, .btn:focus,
button:hover, button:focus {
    outline: none !important;
}

.btn.disabled,
.btn.disabled:hover,
.btn[disabled]:hover,
fieldset[disabled] .btn:hover,
.btn.disabled:focus,
.btn[disabled]:focus,
fieldset[disabled] .btn:focus,
.btn.disabled:active,
.btn[disabled]:active,
fieldset[disabled] .btn:active,
.btn.disabled.active,
.btn[disabled].active,
fieldset[disabled] .btn.active {
    background-color: #c4c9cf;
    color: #fff;
}

.btn-primary {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #1e9cd2), color-stop(100%, #1c92c4));
    background: -webkit-linear-gradient(#1e9cd2, #1c92c4);
    background: linear-gradient(#1e9cd2, #1c92c4);
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #20a6df), color-stop(100%, #1e9cd2));
    background: -webkit-linear-gradient(#20a6df, #1e9cd2);
    background: linear-gradient(#20a6df, #1e9cd2);
    box-shadow: 0 1px 0 0 rgba(255,255,255,0.07) inset,0 1px 2px 0 rgba(0,0,0,0.12),0 1px 0 0 rgba(0,0,0,0.08);
    border-color: #3677B2;
}

.btn-success {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #62D69A), color-stop(100%, #33C77A));
    background: -webkit-linear-gradient(#62D69A, #33C77A);
    background: linear-gradient(#62D69A, #33C77A);
    border-color: #4DBB82;
}

.btn-success:active,
.btn-success:hover,
.btn-success:focus {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #62D69A), color-stop(100%, #62C86E));
    background: -webkit-linear-gradient(#62D69A, #62C86E);
    background: linear-gradient(#62D69A, #62C86E);
    border-color: #44aa75;
}

.btn-white {
    background: #fff;
    border-color: #f7f8f9;
    color: #333;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    background: #F5F5F5;
    border-color: #eaeded;
}

.btn-default {
    background: #fff;
    border-color: #f7f8f9;
    color: #333;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    background: #F5F5F5;
    border-color: #eaeded;
}

.btn-info {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #41D9F4), color-stop(100%, #3DC2DA));
    background: -webkit-linear-gradient(#41D9F4, #3DC2DA);
    background: linear-gradient(#41D9F4, #3DC2DA);
    border-color: #3DC2DA;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #41D9F4), color-stop(100%, #3DC2DA));
    background: -webkit-linear-gradient(#41D9F4, #3DC2DA);
    background: linear-gradient(#41D9F4, #3DC2DA);
    border-color: #3DC2DA;
}

.btn-inverse {
    background-color: #233445;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active {
    background-color: #314355;
}

.btn-warning {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f6d616), color-stop(100%, #f4c414));
    background: -webkit-linear-gradient(#f6d616, #f4c414);
    background: linear-gradient(#f6d616, #f4c414);
    border-color: #f4c414;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f4c414), color-stop(100%, #f6d616));
    background: -webkit-linear-gradient(#f4c414, #f6d616);
    background: linear-gradient(#f4c414, #f6d616);
    border-color: #ddb110;
}

.btn-danger {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ea817b), color-stop(100%, #e87973));
    background: -webkit-linear-gradient(#ea817b, #e87973);
    background: linear-gradient(#ea817b, #e87973);
    border-color: #ff5f5f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e87973), color-stop(100%, #ea817b));
    background: -webkit-linear-gradient(#e87973, #ea817b);
    background: linear-gradient(#e87973, #ea817b);
    border-color: #e15454;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    background-color: transparent;
    background-image: none;
    border: none;
    color: #3a5a7a;
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}


td input[type="button"],
td input[type="submit"],
td .btn, td button {
    height: 22px;
    line-height: 22px !important;
    padding: 0 4px;
}

.btn-group,
.btn-group-vertical {
    line-height: 30px;
}