@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,400,300,600);
@import "vendor/bootstrap";
$fa-font-path:        "/fonts" !default;
@import "vendor/fontawesome/_font-awesome";
@import "vendor/chosen";
@import "vendor/chartist";
@import "helpers/mixins";
@import "modules/buttons";
@import "modules/navbar";
@import "modules/tables";


html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Open sans', sans-serif;
    padding-top: 50px;
    font-size: 12px;
}

a:hover {
    text-decoration: none;
}

.relative {
    position: relative;
}

ul > li {
    list-style: none;
}

ul.inline {
    li {
        float: left;
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

ul > li,
ol > li {
    line-height: 26px;
}

ul.list {
    padding-left: 20px;

    li {
        list-style: inherit;
    }
}

small {
    font-size: 90%;
    line-height: 150% !important;
}

.text-warning {
    color: #D2BB00;
}

.text-danger {
    color: #FF0000;
}

.text-success {
    color: #09C700;
}

.template {
    display: none;
}

.full-height {
    @include full-height();
}

.block {
    display: block;
}

.clear:after {
    clear: both;
    content: '';
    display: block;
}

.upper {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.truncate {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-left {
    text-align: left !important;
}

h1, .h1 { font-size: 28px; }
h2, .h2 { font-size: 24px; }
h3, .h3 { font-size: 16px; }
h4, .h4 { font-size: 14px; }
h5, .h5 { font-size: 12px; }

.page-header {
    margin: 20px 0;
}

.m-b-lg { margin-bottom: 40px; }
.m-b-md { margin-bottom: 30px; }
.m-b-sm { margin-bottom: 20px; }
.m-b-xs { margin-bottom: 10px; }
.m-b-none { margin-bottom: 0; }

.m-t-lg { margin-top: 40px; }
.m-t-md { margin-top: 30px; }
.m-t-sm { margin-top: 20px; }
.m-t-xs { margin-top: 10px; }
.m-t-none { margin-top: 0; }

.p-none { padding: 0; }
.m-none { margin: 0; }

.m-lr-xs {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.m-lr-sm {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.m-lr-md {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.m-lr-lg {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.p-t-lg { padding-top: 30px; }
.p-t-md { padding-top: 20px; }
.p-t-sm { padding-top: 10px; }
.p-t-xs { padding-top: 5px; }

.p-lr-xs {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.p-lr-sm {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.p-lr-md {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.p-lr-lg {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.bold {
    font-weight: bold;
}

#login {
    @include full-height();
    padding-top: 40px;
}

.form-control {
    font-size: 12px;
    border-radius: 4px;
    height: 30px;
    line-height: 28px;
}

textarea.form-control {
    max-height: 120px;
}

.pagination {
    margin: 0;

    > li > a,
    > li > span {
        font-size: 11px;
    }
}

ul.picture-list {
    padding: 0;

    li {
        position: relative;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        a.picture-download {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size: 20px;
        }
    }
}

.footer {
    font-size: 11px;
}

.order-comment-box {
    position: relative;

    .comment {
        width: 100%;
        height: 20px;
    }

    .commentEdit {
        display: none;
        position: absolute;
        right: 0;
        top: 25px;
        width: 900px;
    }
}