@mixin box-sizing() {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

@mixin prevent-selection() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin full-height() {
    height: 100%;
    min-height: 100%;
}

@mixin transition( $type, $speed : .3s, $effect : ease-in-out ) {
    -webkit-transition: $type $speed $effect;
       -moz-transition: $type $speed $effect;
        -ms-transition: $type $speed $effect;
         -o-transition: $type $speed $effect;
            transition: $type $speed $effect;
    -webkit-transition-duration: $speed;
       -moz-transition-duration: $speed;
        -ms-transition-duration: $speed;
         -o-transition-duration: $speed;
            transition-duration: $speed;
}

@mixin transform( $what ) {
    -webkit-transform: $what; /* Safari */
       -moz-transform: $what; /* Firefox 3.6 Firefox 4 */
        -ms-transform: $what; /* IE9 */
         -o-transform: $what; /* Opera */
            transform: $what; /* W3C */
}

@mixin center( $xy:xy ) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform( translateX( -50% ) );
        @include transform( translateY( -50% ) );
    }

    @else if $xy == x {
        left: 50%;
        right: auto;
        @include transform( translateX( -50% ) );
    }

    @else if $xy == y {
        top: 50%;
        bottom: auto;
        @include transform( translateY( -50% ) );
    }
}
