.navbar {
    min-height: 34px;

    &.navbar-dark {
        background: #2f2f3c;

        .navbar-nav > li > a {
            color: #AAAAAA;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 34px;

            &:hover, &:focus {
                background: transparent;
                color: #eeeeef;
            }
        }

        .navbar-nav li.open > a,
        .navbar-nav li.open > a:hover,
        .navbar-nav li.open > a:focus {
            background-color: #3C3F4C;
            border: 0;
            color: #eeeeef;
        }

        .navbar-nav > li.active > a {
            color: #eeeeef;

            &:hover {
                color: #fcfcfc;
            }
        }
    }

    .navbar-nav > li > a {
        padding-bottom: 7px;
        padding-top: 7px;
    }

    .navbar-brand {
        height: 34px;
        padding: 7px 15px;
        font-size: 16px;
    }
}