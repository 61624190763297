table, .table {
    font-size: 12px;

    .label {
        font-size: 90%;
    }

    .checkbox-column {
        padding-left: 1em;
        padding-right: 1em;
    }

    > thead > tr {
        > th {
            text-transform: uppercase;
            font-size: 11px;
            border-bottom: 2px solid #eeeeef;
        }
    }

    > tbody > tr {
        &:hover > td:first-child:before {
            opacity: 1;
        }

        &:first-child td {
            border-top: 0;
        }

        > td {
            line-height: 20px;
            border-top: 1px solid #eeeeef;

            &:first-child {
                position: relative;
            }

            &:first-child:before {
                content: "";
                display: block;
                position: absolute;
                top: -1px;
                left: 0;
                width: 2px;
                height: calc(100% + 2px);
                background: #8889c3;
                opacity: 0;
            }

            .text-wrap {
                height: 5rem;
                position: relative;
                display: block;
                -moz-box-sizing: content-box;
                box-sizing: content-box;

                .text-content {
                    position: absolute;
                    max-width: 100%;

                    * {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 1rem;
                    }

                    h5 {
                        font-size: 18px;
                        margin: 0;
                    }

                    .text-headline {
                        line-height: 160%;

                        .error-class {
                            font-weight: 600;
                            color: #2f2f3c;

                            &:after {
                                content: "·";
                                color: #eeeeef;
                                margin: 0 .2em;
                            }
                        }

                        .error-location {
                            color: #747474;
                        }
                    }

                    .text-message {
                        margin: 0;
                        color: #76767a;
                        font-style: italic;
                        line-height: 180%;
                    }
                }
            }
        }
    }

    > thead > tr > th,
    > tbody > tr > td {
        padding: 4px 1.2em 4px 1.2em;
    }

    &.table-hover > tbody > tr {
        &:hover > td, &:hover > th {
            background-color: #FAFAFA;
        }
    }
}